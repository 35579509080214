import Accordion from '@/components/Accordion/Accordion'
import ProgramCard from '@/components/ProgramCard/ProgramCard'
import { UtilityProgram } from '@/types/utilityProgram'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
  programs: UtilityProgram[]
  title: string
  enrolled?: boolean
  initialState?: 'open' | 'closed'
}
export default function ProgramCardAccordion(props: Props) {
  return (
    <AnimatePresence mode="wait">
      {props.programs && props.programs.length > 0 && (
        <motion.div
          key="program-card-accordion"
          initial={{ opacity: 0, height: 0, marginBottom: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0, marginBottom: 0 }}
          transition={{
            duration: 0.3,
            ease: 'easeOut',
          }}
          className="w-full hidden"
        >
          <Accordion title={props.title} initialState={props?.initialState}>
            <div className={'flex flex-col gap-2'}>
              {props.programs.map((program) => (
                <ProgramCard
                  program={program}
                  key={program.name}
                  enrolled={props.enrolled}
                />
              ))}
            </div>
          </Accordion>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
