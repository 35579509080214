import { Vehicle, VehicleChargeSchedule } from '@/types'
import { VehicleTrip } from '@/reducers/trips'
import {
  convertTimeTo12HourFormat,
  getNextChargeDate,
  getNextDepartureDate,
  isTodayOrTomorrow,
  toLongTimeString,
} from '@/utils/date'
import { ChargeScheduleDaysOfWeek } from '@/types/chargeSchedule'
import { isAfter, isBefore } from 'date-fns'
import { isTesla } from '@/utils/vehicles/car'
import { canCharge } from '@/utils/vehicles/permissions'

function generateGoalsText(chargeSchedule?: VehicleChargeSchedule) {
  const plans = []
  if (!chargeSchedule) {
    return 'No goals selected'
  }

  if (chargeSchedule.save_the_planet_plan_enabled) {
    plans.push('Save the Planet')
  }
  if (chargeSchedule.money_saving_plan_enabled) {
    plans.push('Save Money')
  }
  if (chargeSchedule.solar_surplus_plan_enabled) {
    plans.push('Solar Surplus')
  }

  if (plans.length === 0) {
    plans.push('No goals selected')
  }

  return plans.join(', ')
}

function generateLimitsText(chargeSchedule?: VehicleChargeSchedule) {
  if (!chargeSchedule) {
    return 'Charge limits are not currently controllable for this vehicle'
  }

  return `Min - ${chargeSchedule.battery_target_min}%, Max - ${chargeSchedule.battery_target_max}%`
}

function generateTripsText(trips?: VehicleTrip[]) {
  const upcomingTrips = trips?.filter((trip) => {
    const date = new Date(trip.departure_datetime_utc)
    const now = new Date()

    return now < date
  })
  if (!upcomingTrips || upcomingTrips.length === 0) {
    return 'No upcoming trips planned for this vehicle'
  }
  const nextTrip = upcomingTrips[0]

  const dateStr = toLongTimeString(nextTrip.departure_datetime_utc)
  return `Next: Hit ${nextTrip.battery_target_max}% on ${dateStr}`
}

// This is done in support of adding the next Day of Week to the schedule subtitle
// By converting the string day to a number we can easily figure out when the next
// scheduled day of week will be
const sortUpcomingSchedules = (
  schedules: ChargeScheduleDaysOfWeek[],
  property: 'departure_time' | 'charging_start_time',
) => {
  return schedules
    .filter((charge) => !!charge[property])
    .map((charge) => {
      if (charge.day_of_week == null) {
        return { ...charge, startDate: null }
      }

      if (property === 'departure_time') {
        return { ...charge, startDate: getNextDepartureDate(charge) }
      }
      if (property === 'charging_start_time') {
        return { ...charge, startDate: getNextChargeDate(charge) }
      }
    })
    .sort((a, b) => {
      if (!a || !a.startDate) {
        return -1
      }
      if (!b || !b.startDate) {
        return 1
      }

      return isAfter(a.startDate, b.startDate) ? 1 : -1
    })
}

function generateScheduleSubtitle(chargeSchedule?: VehicleChargeSchedule) {
  if (
    !chargeSchedule ||
    chargeSchedule.charge_schedule_days_of_week.length === 0
  ) {
    return 'No scheduled departure/charging planned'
  }

  const departures = sortUpcomingSchedules(
    chargeSchedule.charge_schedule_days_of_week,
    'departure_time',
  )

  const scheduledCharges = sortUpcomingSchedules(
    chargeSchedule.charge_schedule_days_of_week,
    'charging_start_time',
  )

  const today = new Date()
  const nextDeparture = departures.find((dep) => {
    if (!dep || !dep.startDate) {
      return false
    }
    return isBefore(today, dep.startDate) && isTodayOrTomorrow(dep.startDate)
  })

  const nextCharge = scheduledCharges.find((charge) => {
    if (!charge || !charge.startDate) {
      return false
    }

    if (nextDeparture && nextDeparture.startDate) {
      return (
        isBefore(charge.startDate, nextDeparture.startDate) &&
        isBefore(today, charge.startDate) &&
        isTodayOrTomorrow(charge.startDate)
      )
    }

    return (
      isBefore(today, charge.startDate) && isTodayOrTomorrow(charge.startDate)
    )
  })

  const strings = []

  if (chargeSchedule.schedule_start_charge_enabled && nextCharge) {
    const time = convertTimeTo12HourFormat(nextCharge.charging_start_time)
    strings.push(`Start at ${time}`)
  }

  if (chargeSchedule.schedule_departure_enabled && nextDeparture) {
    const time = convertTimeTo12HourFormat(nextDeparture.departure_time)
    strings.push(`Depart at ${time}`)
  }

  if (strings.length === 0) {
    return 'No scheduled departure/charging planned'
  }

  const joinedStrings = strings.join(', ')
  return `Next: ${joinedStrings}`
}

function getAlert(
  utilityAlert: string,
  vehicleCanCharge: boolean,
): string | undefined {
  if (utilityAlert) {
    return utilityAlert
  }

  if (!vehicleCanCharge) {
    return 'This vehicle does not currently support third-party charge management and functionality will be limited.'
  }

  return undefined
}

const vehicleCanCharge = (vehicle: Vehicle | undefined): boolean => {
  return !!(
    vehicle &&
    canCharge(vehicle) &&
    (!isTesla(vehicle) || !vehicle.car?.public_key_not_attached_at)
  )
}

function getConnectionText(vehicle: Vehicle | undefined): string {
  if (!vehicle) {
    return ''
  }

  if (isTesla(vehicle)) {
    // if the vehicle has no public key, show that first because
    // we need that for any sort of data or control
    if (vehicle.car?.public_key_not_attached_at) {
      return 'Pair your Virtual Key'
    }
    // if permissions are the problem
    else if (!canCharge(vehicle)) {
      return 'Enable Permissions'
    }
  }

  return 'Connect EVSE'
}

export default {
  getAlert,
  generateGoalsText,
  generateLimitsText,
  generateTripsText,
  generateScheduleSubtitle,
  getConnectionText,
  vehicleCanCharge,
}
