import { getChargeSchedules, updateChargeSchedule } from '@/actions/schedule'
import { useNavigation } from '@/app/hooks'
import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { RootState } from '@/reducers'
import { vehicleEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'
import { selectVehicleEnrollmentForUser } from '@/selectors'
import { Vehicle, VehicleChargeSchedule, VehicleEnrollment } from '@/types'
import { enrollmentIsUnenrolled } from '@/types/utilityProgramEnrollment'
import { canCharge } from '@/utils/vehicles/permissions'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

const useViewModel = (vehicle: Vehicle) => {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  vehicleEnrollmentCollection.useFetch()
  const chargeSchedules = useVehicleChargeSchedulesFetcher()
  const chargeSchedule = (chargeSchedules ?? []).find(
    (schedule) => schedule.vehicle_id === vehicle?.id,
  ) as VehicleChargeSchedule | undefined
  const [localChargeSchedule, setLocalChargeSchedule] = useState<
    VehicleChargeSchedule | undefined
  >(chargeSchedule)
  const canVehicleCharge = canCharge(vehicle)
  const enrolledVehicleEnrollment = useAppSelector((state: RootState) =>
    getEnrolledVehicleEnrollment(state, vehicle.id),
  )
  const isFromPartner =
    useAppSelector((state: RootState) => state.auth.fromToken) ?? false

  const utilityAlertSubtitle = getUtilityAlertSubtitle(
    isFromPartner,
    enrolledVehicleEnrollment,
  )
  const overrideValue = getOverrideValue(
    isFromPartner,
    enrolledVehicleEnrollment,
  )
  const userCanControlCharging = getUserCanControlCharging(
    canVehicleCharge,
    enrolledVehicleEnrollment,
    localChargeSchedule,
  )

  useEffect(() => {
    dispatch(getChargeSchedules())
  }, [])

  useEffect(() => {
    if (localChargeSchedule?.id !== chargeSchedule?.id && chargeSchedule) {
      setLocalChargeSchedule(chargeSchedule)
    }
  }, [chargeSchedule, localChargeSchedule])

  // Ensure the local charge schedule is in sync with what we pull from the server.
  // This fixed a bug where local charge schedule was not being initialized properly
  // when the component was first mounted.
  useEffect(() => {
    if (localChargeSchedule?.id !== chargeSchedule?.id && chargeSchedule) {
      setLocalChargeSchedule(chargeSchedule)
    }
  }, [chargeSchedules, localChargeSchedule])

  const debouncedSave = useCallback(
    debounce(async (model: VehicleChargeSchedule) => {
      await dispatch(updateChargeSchedule(model))
    }, 500),
    [],
  )

  const onChargeScheduleChange = (chargeSchedule: VehicleChargeSchedule) => {
    // reflect change locally
    setLocalChargeSchedule(chargeSchedule)
    // sync to server
    debouncedSave(chargeSchedule)
  }

  const onTripsClick = () => {
    navigation.push(`/devices/vehicle/${vehicle.id}/trips`)
  }

  const onScheduleClick = () => {
    navigation.push(`/devices/vehicle/${vehicle.id}/schedule`)
  }

  const onPlansClick = () => {
    navigation.push(`/devices/vehicle/${vehicle.id}/plans`)
  }

  const onLimitsClick = () => {
    navigation.push(`/devices/vehicle/${vehicle.id}/limits`)
  }

  return {
    chargeSchedule,
    canVehicleCharge,
    enrolledVehicleEnrollment,
    onChargeScheduleChange,
    localChargeSchedule,
    utilityAlertSubtitle,
    overrideValue,
    userCanControlCharging,
    onTripsClick,
    onScheduleClick,
    onPlansClick,
    onLimitsClick,
  }
}

const getEnrolledVehicleEnrollment = (
  state: RootState,
  vehicleId: number,
): VehicleEnrollment | undefined => {
  const enrollment = selectVehicleEnrollmentForUser(state, vehicleId)
  if (!enrollment || enrollmentIsUnenrolled(enrollment)) {
    return undefined
  }
  return enrollment
}

const getUtilityAlertSubtitle = (
  isFromPartner: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
): string | undefined => {
  if (isFromPartner) {
    return 'You must allow Optiwatt to manage charging in order to participate in this program.'
  }
  return enrolledVehicleEnrollment?.view_config_json?.scheduleCharging?.subtitle
}

const getOverrideValue = (
  isFromPartner: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
): boolean | null | undefined => {
  if (isFromPartner) {
    return true
  }
  return enrolledVehicleEnrollment?.group?.allow_charging_control
}

const getUserCanControlCharging = (
  canVehicleCharge: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
  chargeSchedule: VehicleChargeSchedule | undefined,
): boolean => {
  if (!canVehicleCharge) {
    return false
  }

  const allowChargingControl =
    enrolledVehicleEnrollment?.group?.allow_charging_control ??
    chargeSchedule?.allow_charging_control ??
    true

  return allowChargingControl
}

export default useViewModel
