import { Icon, LoadingAnimation, Text } from '@/components'
import { Divider } from '@material-ui/core'
import InfoCard from '@/app/components/InfoCard/InfoCard'
import Card from '@/components/Card'
import useViewModel from '@/app/pages/devices/vehicle/SettingsTab/ChargeSettingsCard/useViewModel'
import IconToggleLabel from '@/authenticated/components/IconToggleLabel'
import { AlertMessage } from 'app-components'
import { sanitizeHtmlContent } from '@/utils/components'
import DropdownAnimation from '@/components/Animations/DropdownAnimation'

export default function ChargeSettingsCard() {
  const viewModel = useViewModel()

  if (viewModel.vehicleAuthenticationInactive) {
    return null
  }

  if (viewModel.isLoading) {
    return <LoadingAnimation type="falling" centered />
  }

  return (
    <Card>
      <div className="flex flex-col">
        <div className="flex justify-between items-center gap-2.5 py-4">
          <div className="flex gap-2.5">
            <Icon name="Zap" className={`w-[24px] text-themed-primary`} />
            <Text variant="h3">Charge Settings</Text>
          </div>
          {viewModel.forceChargeControl && (
            <IconToggleLabel
              id="allow-charge-control-toggle"
              model={{ forced: true }}
              field="forced"
              disabled
              allowDisabledEnabled
            />
          )}
          {!viewModel.forceChargeControl && (
            <IconToggleLabel
              id="allow-charge-control-toggle"
              model={viewModel.chargeSchedule}
              field="allow_charging_control"
              shouldDebounce={false}
              onChange={viewModel.onToggleChargeControl}
              disabled={!viewModel.vehicleCanCharge}
            />
          )}
        </div>
        {viewModel.alert && (
          <div className="mt-4">
            <AlertMessage
              show
              variant={viewModel.vehicleCanCharge ? 'error' : 'info'}
              style={{ marginTop: 10 }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtmlContent(viewModel.alert),
                }}
              />
            </AlertMessage>
          </div>
        )}
      </div>
      <DropdownAnimation show={viewModel.settingsEnabled}>
        {viewModel.vehicleCanCharge && (
          <>
            <Divider className="my-4" />
            <InfoCard
              variant="large"
              image={<Icon name="Battery" className={`w-[20px] mr-2.5`} />}
              title="Limits"
              subtitle={viewModel.limitsSubtitle}
              onClick={() => viewModel.deviceNav('limits')}
            />
            <Divider className="my-4" />
            <InfoCard
              variant="large"
              image={<Icon name="Target2" className={`w-[20px] mr-2.5`} />}
              title="Goals"
              subtitle={viewModel.goalsSubtitle}
              onClick={() => viewModel.deviceNav('goals')}
            />
            <Divider className="my-4" />
            <InfoCard
              variant="large"
              image={<Icon name="Clock" className={`w-[20px] mr-2.5`} />}
              title="Schedule"
              subtitle={viewModel.scheduleSubtitle}
              onClick={() => viewModel.deviceNav('scheduling')}
            />
            <Divider className="my-4" />
            <InfoCard
              variant="large"
              image={<Icon name="Calendar" className={`w-[20px] mr-2.5`} />}
              title="Trips"
              subtitle={viewModel.tripsSubtitle}
              onClick={() => viewModel.deviceNav('trips')}
            />
          </>
        )}
      </DropdownAnimation>
      {!viewModel.vehicleCanCharge && (
        <>
          <Divider className="my-4" />
          <InfoCard
            variant="large"
            image={<Icon name="Cupcake" className={`w-[20px] mr-2.5`} />}
            title="More"
            subtitle="Capabilities limited,"
            disabled
            subtitleLink={viewModel.failedConnectionText}
            onSubtitleLink={viewModel.connectionSubtitleClicked}
          />
        </>
      )}
    </Card>
  )
}
