import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown } from 'lucide-react'
import Text from '@/components/Text/Text'
type Props = {
  title: string
  children: React.ReactNode
  initialState?: 'open' | 'closed'
}
export default function Accordion({ title, children, initialState }: Props) {
  const [isOpen, setIsOpen] = useState(
    (initialState ?? 'closed') === 'open' ? true : false,
  )

  return (
    <div className="w-full rounded-2xl !p-0 !m-0 overflow-hidden">
      <div
        className={
          'flex justify-between items-center bg-white rounded-2xl p-4 cursor-pointer'
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text variant={'h3'}>{title}</Text>
        <motion.div
          animate={{ height: 20, width: 20, rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown size={20} className={'text-base-grey-400'} />
        </motion.div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="bg-none mt-2">{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
