import {
  AlertMessage,
  BehindFlag,
  ConnectionAlertMessage,
} from '@/app/components'
import { useNavigation } from '@/app/hooks'
import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import MenuCard, { MenuCardItem } from '@/components/MenuCard'
import { useAppSelector } from '@/hooks'
import { thermostatEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'
import { thermostatComfortSettingsCollection } from '@/reducers/thermostatComfortSettings'
import {
  thermostatPrecoolSavingsSummariesCollection,
  thermostatSavingsSummariesCollection,
} from '@/reducers/thermostatSavingsSummaries'
import { thermostatSettingsCollection } from '@/reducers/thermostatSettings'
import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import {
  Thermostat,
  ThermostatSavingsSummary,
  ThermostatSettings,
} from '@/types'
import { ThermostatPrecoolSavingsSummary } from '@/types/thermostatSavingsSummary'
import { centsToDollars } from '@/utils/currency'
import HomeEstimation from '@/app/components/HomeEstimations/Prompt/HomeEstimation'
import useHomeEstimationNavigation, {
  HomeEstimationPages,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import { logEvent } from '@/logging'
import Experimental from '@/authenticated/components/Experimetal'
import { thermostatsCollection } from '@/reducers/thermostats'
import { homesCollection } from '@/reducers/homeResource'
import ProgramCards from '@/app/components/ProgramCards/ProgramCards'

type Props = {
  thermostatId: Thermostat['id']
}

function usePrefetches(thermostatId: Thermostat['id']) {
  thermostatComfortSettingsCollection.useFetch(
    `/thermostats/${thermostatId}/comfort_settings/`,
  )
}

function useViewModel(props: Props) {
  usePrefetches(props.thermostatId)
  const navigation = useNavigation()
  const { data: thermostats } = thermostatsCollection.useFetch()
  const { data: home } = homesCollection.useFetch()

  const homeEstimationNavigation = useHomeEstimationNavigation()
  const utilityEnrollment = useAppSelector(
    selectUtilityProgramEnrollmentForUser,
  )
  // Determine if the thermostat is eligible for enrollment in a utility program
  const isEligible =
    utilityEnrollment?.utility_program.device_eligibility?.eligible_thermostat_ids.includes(
      props.thermostatId,
    )
  const isEnrolled = utilityEnrollment?.thermostat_ids?.includes(
    props.thermostatId,
  )
  const validEnrollment = utilityEnrollment && (isEligible || isEnrolled)

  const { data: thermostatEnrollments } =
    thermostatEnrollmentCollection.useFetch()
  const thermostatEnrollment = thermostatEnrollments.find(
    (enrollment) => enrollment.thermostat_id === props.thermostatId,
  )
  const { data: savingsSummaryList } =
    thermostatSavingsSummariesCollection.useFetch(
      `/thermostats/${props.thermostatId}/savings_summary/`,
    )
  const { data: precoolSavingsSummaryList } =
    thermostatPrecoolSavingsSummariesCollection.useFetch(
      `/thermostats/${props.thermostatId}/savings_summary/pre_cool/`,
    )
  const savingsSummary = savingsSummaryList[0] as
    | ThermostatSavingsSummary
    | undefined
  const precoolSavingsSummary = precoolSavingsSummaryList[0] as
    | ThermostatPrecoolSavingsSummary
    | undefined
  const gridSupportSavingsDollars = Math.ceil(
    centsToDollars(savingsSummary?.grid_support_savings_cents ?? 0),
  )
  const precoolSavingsDollars = Math.ceil(
    centsToDollars(precoolSavingsSummary?.pre_cool_savings_cents ?? 0),
  )
  const showHomeEstimations = useFeatureFlag({
    flag: 'show_home_estimations',
    experimentalValue: true, // TODO AVild - probably off?
  })
  const {
    data: settingsArray,
    status: settingsStatus,
    error: settingsError,
  } = thermostatSettingsCollection.useFetch(
    `/thermostats/${props.thermostatId}/settings/`,
  )
  const settings = settingsArray[0] as ThermostatSettings | undefined

  const gridSupportSavings =
    savingsSummary && settings?.grid_support_enabled
      ? gridSupportSavingsDollars
      : undefined
  const precoolSavings =
    precoolSavingsSummary && settings?.pre_cool_enabled
      ? precoolSavingsDollars
      : undefined

  let enrollmentLabelText = 'Unenrolled'
  const enableThermostatEnrollment = useFeatureFlag({
    flag: 'thermostat_enrollments',
  })

  if (
    thermostatEnrollment &&
    (thermostatEnrollment.unenrolled_at === null ||
      thermostatEnrollment.unenrolled_at === undefined)
  ) {
    enrollmentLabelText = 'Enrolled'
  }

  return {
    gridSupportSavings,
    precoolSavings,
    settings: settings,
    settingsError: settingsError,
    utilityEnrollment,
    validEnrollment,
    enrollmentLabelText,
    enableThermostatEnrollment,
    loading: settingsStatus === 'loading',
    navigateToGridSupport: () => {
      navigation.push(`/devices/thermostat/${props.thermostatId}/grid-support`)
    },
    navigateToPrecoolPreheatSettings: () => {
      navigation.push(
        `/devices/thermostat/${props.thermostatId}/precool-preheat-settings`,
      )
    },
    navigateToNotificationSettings: () => {
      navigation.push(
        `/devices/thermostat/${props.thermostatId}/notification-settings`,
      )
    },
    navigateToUtilityProgramEnrollment: () => {
      navigation.push(
        `/devices/thermostat/${props.thermostatId}/utility-enrollment`,
      )
    },
    navigateToHomeEstimations: () => {
      logEvent('Schedule_Settings_Card_Clicked')
      homeEstimationNavigation.push(
        HomeEstimationPages.Home,
        undefined,
        `devices/thermostat/${props.thermostatId}`,
      )
    },
    showHomeEstimations,
  }
}

function ThermostatScheduleSectionComponent(props: Props) {
  const {
    gridSupportSavings,
    precoolSavings,
    settings,
    settingsError,
    loading,
    utilityEnrollment,
    validEnrollment,
    enableThermostatEnrollment,
    enrollmentLabelText,
    navigateToGridSupport,
    navigateToPrecoolPreheatSettings,
    navigateToNotificationSettings,
    navigateToUtilityProgramEnrollment,
    navigateToHomeEstimations,
    showHomeEstimations,
  } = useViewModel(props)

  return (
    <>
      <ConnectionAlertMessage connectionRequirements={['meter', 'utility']} />
      {settingsError ? (
        <AlertMessage variant="error">
          There was an error loading your thermostat schedule settings. Please
          try again later.
        </AlertMessage>
      ) : (
        <>
          <Experimental>
            <ProgramCards />
          </Experimental>
          <HomeEstimation
            variant="alert"
            from={'devices/thermostat/' + props.thermostatId}
          />
          {validEnrollment && enableThermostatEnrollment && (
            <MenuCard
              title={
                utilityEnrollment?.utility_program.view_config_json?.scheduler
                  .title
              }
            >
              <MenuCardItem
                id="thermostat-utility-program"
                loading={loading}
                rightLabel={enrollmentLabelText}
                label="Manage Enrollment"
                onClick={navigateToUtilityProgramEnrollment}
              />
            </MenuCard>
          )}
          <MenuCard icon="Zap" iconColor="#FBD242" title="Be a Watt Hero">
            <BehindFlag flag="thermostat_pre_cool_settings">
              <MenuCardItem
                id="thermostat-grid-support-menu-item"
                loading={loading}
                rightLabel={settings?.pre_cool_enabled ? 'On' : 'Off'}
                label="Precool"
                sheetValue={precoolSavings ? `$${precoolSavings}` : undefined}
                onClick={navigateToPrecoolPreheatSettings}
              />
            </BehindFlag>
            <MenuCardItem
              id="thermostat-grid-support-menu-item"
              loading={loading}
              rightLabel={settings?.grid_support_enabled ? 'On' : 'Off'}
              label="Grid Support"
              sheetValue={
                gridSupportSavings ? `$${gridSupportSavings}` : undefined
              }
              onClick={navigateToGridSupport}
            />
          </MenuCard>
          <MenuCard icon="Settings" title="Schedule Settings">
            {showHomeEstimations && (
              <MenuCardItem
                id="thermostat-home-estimations-settings-menu-item"
                loading={loading}
                rightLabel={''}
                label="Home estimations"
                onClick={navigateToHomeEstimations}
              />
            )}
            <MenuCardItem
              id="thermostat-notification-settings-menu-item"
              loading={loading}
              rightLabel={settings?.notifications_enabled ? 'On' : 'Off'}
              label="Notifications"
              onClick={navigateToNotificationSettings}
            />
          </MenuCard>
        </>
      )}
    </>
  )
}

export default function ThermostatScheduleSection(props: Props) {
  return (
    <ErrorBoundary
      location={LocationTag.ThermostatSchedule}
      functionalityDescription="Thermostat Schedule Settings"
      severity="critical"
    >
      <BehindFlag flag="thermostat_schedule_settings" experimental>
        <ThermostatScheduleSectionComponent {...props} />
      </BehindFlag>
    </ErrorBoundary>
  )
}
