import {
  EnrollmentVerificationStatusColor,
  UtilityProgram,
} from '@/types/utilityProgram'
import { Text } from '@/components'
import {
  CheckIcon,
  ChevronRightIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ShareIcon,
} from 'lucide-react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { useBottomSheet } from '@/context/bottom-sheet-context'
import { programRequirementsSheetConfig } from '@/app/components/ProgramRequirementsSheet/ProgramRequirementsSheet'
import differenceInDays from 'date-fns/esm/fp/differenceInDays'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import {
  CLIPBOARD_COPY_TYPE,
  postMessagetoMobileIfPresent,
} from '@/utils/messageUtils'
import { toTitleCase } from '@/utils/string'
import { useNavigation } from '@/app/hooks'

type Props = { program: UtilityProgram; enrolled?: boolean }
export default function ProgramCard({ program, enrolled }: Props) {
  const [isSeeMoreOpen, setIsSeeMoreOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const { setBottomSheetContent, snapToHeader } = useBottomSheet()
  const navigate = useNavigation()
  const days = differenceInDays(
    new Date(),
    new Date(program.view_config_json.programCard?.enrollmentEndDate),
  )
  const statusText = useMemo(() => {
    if (!enrolled) {
      return days > 0 ? 'Due in' : 'Expired'
    }
    return 'Status'
  }, [days, enrolled])
  const statusDescription = useMemo(() => {
    if (!program.verification_status) {
      return 'N/A'
    }
    return toTitleCase(program.verification_status?.toString())
  }, [program])
  const daysText = days > 0 ? 'days' : 'days ago'
  const taglineParts =
    program.view_config_json.programCard?.savingsTagline?.split(' ')
  // referral $ plus default $5
  const referralDollars = program.referral_points
    ? program.referral_points + 5
    : 5

  async function handleShare(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    const url = program.view_config_json.programCard?.shareLinkUrl
    if (!url) {
      return
    }
    if (isNativeMobile()) {
      postMessagetoMobileIfPresent(url, CLIPBOARD_COPY_TYPE)
    } else {
      await navigator.clipboard.writeText(url)
    }
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3_000)
  }

  function handleOpenSeeMore(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    setIsSeeMoreOpen(!isSeeMoreOpen)
  }

  function handleOpenProgramRequirements() {
    navigate.push(`/utility-program/${program.id}/level-three`)
    if (enrolled) {
      return
    }
    setBottomSheetContent(
      programRequirementsSheetConfig({
        utilityProgram: program,
      }),
    )
    snapToHeader()
  }

  return (
    <section
      className={`flex flex-col w-full ${!enrolled ? 'cursor-pointer' : ''}`}
      onClick={handleOpenProgramRequirements}
    >
      <div
        className={
          'bg-base-green-100 [&>*]:text-base-green-900 flex flex-row gap-1 items-center px-4 py-2 rounded-t-2xl'
        }
      >
        <Text className={'font-semibold'}>{taglineParts?.[0]}</Text>
        <Text variant={'h3'}>{taglineParts?.[1]}</Text>
        <Text className={'font-semibold'}>{taglineParts?.[2]}</Text>
      </div>
      <div className={'bg-base-white p-4 rounded-b-2xl flex flex-col gap-2'}>
        <Text>{program.name}</Text>
        <div
          className={
            'flex flex-row gap-4 md:gap-8 justify-between md:justify-start'
          }
        >
          <div className={'flex flex-col'}>
            <Text variant={'dimmed'}>{statusText}</Text>
            <Text
              className={`${
                !enrolled
                  ? days >= 0
                    ? 'text-base-green-900'
                    : 'text-base-orange-500'
                  : EnrollmentVerificationStatusColor[
                      program.verification_status as keyof typeof EnrollmentVerificationStatusColor
                    ]
              }`}
            >
              {enrolled
                ? statusDescription
                : `${days.toString().replace('-', '')} ${daysText}`}
            </Text>
          </div>
          <div className={'flex flex-row gap-4'}>
            <div className={'w-[1px] bg-base-grey-200 h-full'} />
            <div className={'flex flex-col'}>
              <Text variant={'dimmed'}>Referral</Text>
              <Text className={'text-base-base-500'}>${referralDollars}</Text>
            </div>
          </div>
          <div className={'flex flex-row gap-4'}>
            <div
              className={'w-[1px] bg-base-grey-200 rounded-full h-full'}
            ></div>
            <div className={'flex flex-col mr-8'} onClick={handleShare}>
              <Text variant={'dimmed'}>Share</Text>
              <AnimatePresence mode="popLayout">
                {copied ? (
                  <motion.div
                    key="copied"
                    initial={{ y: 15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -15, opacity: 0 }}
                    className="inline-flex items-center gap-1 w-16"
                  >
                    <Text variant={'body3'}>Copied</Text>
                    <CheckIcon size={18} className="text-base-success" />
                  </motion.div>
                ) : (
                  <motion.div
                    key="copyLink"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -20, opacity: 0 }}
                    className="flex items-center w-16 cursor-pointer"
                  >
                    <ShareIcon size={18} className={'text-base-blue-500'} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          {!enrolled && (
            <div className={`ml-auto flex-none`}>
              <ChevronRightIcon size={20} className={'text-base-base-400'} />
            </div>
          )}
        </div>

        <AnimatePresence>
          {isSeeMoreOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <div
                className={
                  'flex flex-row gap-8 justify-between md:justify-start'
                }
              >
                {program.view_config_json.logoUrl && (
                  <div className={'flex flex-col'}>
                    <Text variant={'dimmed'}>Sponsor</Text>
                    <img
                      src={program.view_config_json.logoUrl ?? ''}
                      alt="program logo"
                      className={'h-16 w-16 flex-none object-contain'}
                    />
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div
          className={
            'flex flex-row gap-1 mt-3 items-center self-center px-2 py-1 hover:bg-base-blue-100 rounded-full cursor-pointer'
          }
          onClick={handleOpenSeeMore}
        >
          <Text className={'text-base-blue-500'}>
            See {isSeeMoreOpen ? 'Less' : 'More'}
          </Text>
          {isSeeMoreOpen ? (
            <MinusCircleIcon
              size={16}
              className={'text-base-white fill-base-blue-500'}
            />
          ) : (
            <PlusCircleIcon
              size={16}
              className={'text-base-white fill-base-blue-500'}
            />
          )}
        </div>
      </div>
    </section>
  )
}
