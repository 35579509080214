export default function toLongTimeString(dateString: string): string {
  const date = new Date(dateString)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Get the formatted parts of the date
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    timeZone: timeZone,
  })

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: timeZone,
  })

  // Get the time zone abbreviation (like EST, PST, etc.)
  const options: {
    timeZoneName:
      | 'long'
      | 'short'
      | 'shortOffset'
      | 'longOffset'
      | 'shortGeneric'
      | 'longGeneric'
      | undefined
    timeZone: string
  } = { timeZoneName: 'short', timeZone: timeZone }
  const timeZoneAbbreviation = date
    .toLocaleTimeString('en-US', options)
    .split(' ')
    .pop()

  return `${formattedDate} at ${formattedTime} ${timeZoneAbbreviation}`
}
