import { useMemo } from 'react'
import ProgramCardAccordion from '@/components/ProgramCardAccordion/ProgramCardAccordion'
import {
  programsNewCollection,
  selectAvailablePrograms,
  selectEnrolledPrograms,
} from '@/reducers/programsNew'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const ProgramCards = () => {
  const enrolledProgramsData = useSelector(selectEnrolledPrograms())
  const enrolledPrograms = useMemo(() => {
    if (!enrolledProgramsData) return []
    return enrolledProgramsData
      .filter((p) => p.view_config_json?.programCard?.savingsTagline)
      .toSorted((a, b) => a.id - b.id)
  }, [enrolledProgramsData])
  programsNewCollection.useFetch()
  const availableProgramsData = useSelector(selectAvailablePrograms())
  const availablePrograms = useMemo(() => {
    if (!availableProgramsData) return []
    return availableProgramsData
      .filter((p) => p.view_config_json?.programCard?.savingsTagline)
      .toSorted((a, b) => a.id - b.id)
  }, [availableProgramsData])
  const location = useLocation()
  const initialState = location.search.includes('showAvailablePrograms')
    ? 'open'
    : 'closed'
  return (
    <>
      <ProgramCardAccordion
        title={`Enrolled Programs (${enrolledPrograms.length})`}
        programs={enrolledPrograms}
        enrolled={true}
      />
      <ProgramCardAccordion
        title={`Available Programs (${availablePrograms.length})`}
        programs={availablePrograms}
        initialState={initialState}
      />
    </>
  )
}
export default ProgramCards
