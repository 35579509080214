import { useMockableViewModel } from '@/hooks'
import { useNavigation } from '@/app/hooks'
import { VehicleChargeSchedule } from '@/types'
import { useState } from 'react'
import useUtilityAlerts from '@/hooks/useUtilityAlerts'
import useVehicleSettingsManager from '@/hooks/useVehicleSettingsManager'
import { useTripsForVehicle } from '@/reducers/trips'
import { RequestStatus } from '@/request/types'
import { isTesla } from '@/utils/vehicles/car'
import utils from './ChargeSettingsCardHelpers'
import { cloneDeep } from 'lodash'
import useNavigateToTeslaKeyPairing from '@/app/hooks/useNavigateToTeslaKeyPairing'

function useViewModel() {
  const { vehicleId, vehicle, chargeSchedule, debouncedSave } =
    useVehicleSettingsManager()
  const navigation = useNavigation()
  const { trips, status: tripStatus } = useTripsForVehicle(vehicleId)
  const [settingsEnabled, setSettingsEnabled] = useState<boolean>(
    chargeSchedule?.allow_charging_control ?? false,
  )

  const [localChargeSchedule, setLocalChargeSchedule] = useState(
    cloneDeep(chargeSchedule),
  )

  const { subtitle: utilityAlert, forceChargeControl } =
    useUtilityAlerts(vehicle)

  const {
    isLoading: isLoadingNavigateToTesla,
    error: navigateToTeslaKeyPairingError,
    navigate: navigateToTeslaKeyPairing,
  } = useNavigateToTeslaKeyPairing()

  const vehicleCanCharge = utils.vehicleCanCharge(vehicle)
  const limitsSubtitle = utils.generateLimitsText(chargeSchedule)
  const goalsSubtitle = utils.generateGoalsText(chargeSchedule)
  const scheduleSubtitle = utils.generateScheduleSubtitle(chargeSchedule)
  const tripsSubtitle = utils.generateTripsText(trips)
  const alert = utils.getAlert(utilityAlert, vehicleCanCharge)
  const disableOptiwattCharging = forceChargeControl || !vehicleCanCharge

  const deviceNav = (path: string) => {
    navigation.push(`/devices/vehicle/${vehicleId}/${path}`)
  }

  const connectionSubtitleClicked = () => {
    if (vehicle && isTesla(vehicle)) {
      if (vehicle.car?.public_key_not_attached_at) {
        navigateToTeslaKeyPairing(vehicle)
        return
      }
      navigation.push('/tesla-authentication/edit-permissions')
      return
    }

    navigation.push('/connect-device/charger')
  }

  const onToggleChargeControl = (chargeSchedule: VehicleChargeSchedule) => {
    if (!chargeSchedule || !localChargeSchedule) {
      return
    }

    const updatedSchedule = {
      ...localChargeSchedule,
      allow_charging_control: !localChargeSchedule.allow_charging_control,
    }
    setLocalChargeSchedule(updatedSchedule)
    setSettingsEnabled(updatedSchedule.allow_charging_control)
    debouncedSave(updatedSchedule)
  }

  const failedConnectionText = vehicle ? utils.getConnectionText(vehicle) : ''

  return {
    isLoading:
      !vehicle ||
      !chargeSchedule ||
      tripStatus == RequestStatus.Loading ||
      isLoadingNavigateToTesla,
    vehicleAuthenticationInactive: vehicle?.authentication_inactive,
    deviceNav,
    connectionSubtitleClicked,
    onToggleChargeControl,
    limitsSubtitle,
    goalsSubtitle,
    scheduleSubtitle,
    tripsSubtitle,
    chargeSchedule: localChargeSchedule,
    vehicleCanCharge,
    forceChargeControl,
    disableOptiwattCharging,
    settingsEnabled: settingsEnabled || forceChargeControl,
    alert,
    failedConnectionText,
  }
}

function useMockViewModel() {
  return {
    isLoading: false,
    vehicleAuthenticationInactive: false,
    deviceNav: () => alert('Nav clicked!'),
    onToggleChargeControl: () => alert('Charge Control status toggled!'),
    connectionSubtitleClicked: () => alert('EVSE clicked'),
    limitsSubtitle: 'Min - 20%, Max - 65%',
    goalsSubtitle: 'Solar Surplus',
    vehicleCanCharge: false,
    scheduleSubtitle: 'Weekdays: Ready By time: 9:00 AM EST',
    tripsSubtitle: 'Upcoming trip: July 31 at 12:00 PM EST',
    chargeSchedule: undefined,
    forceChargeControl: false,
    disableOptiwattCharging: true,
    settingsEnabled: true,
    alert: 'Utility alert!',
    missingPermissions: false,
    failedConnectionText: 'Connect EVSE',
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
