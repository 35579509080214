import { UtilityProgram } from '@/types/utilityProgram'
import { ResourceModelQueryResult } from '@/request/types'
import { UtilityProgramEnrollment } from '@/types'

export function userHasUnenrolledBetterProgram(
  currentEnrollments: ResourceModelQueryResult<UtilityProgramEnrollment>[],
  potentialProgram?: UtilityProgram,
) {
  if (!potentialProgram) {
    return false
  }

  if (currentEnrollments.length > 0) {
    return potentialProgram.id !== currentEnrollments[0].utility_program?.id
  }

  return true
}

const UtilityProgramHelpers = { userHasUnenrolledBetterProgram }

export default UtilityProgramHelpers
