import { ID } from '@/types/model'
import { useProfile } from '@/hooks/useProfileId'
import { homesCollection } from '@/reducers/homeResource'
import { Card, LoadingAnimation, Text } from '@/components'
import UtilityProgramEnrollmentTogglePrompt from 'features/utility-programs/UtilityProgramEnrollmentTogglePrompt'
import { behavioralEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'
import { useState } from 'react'
import ProgramCards from '@/app/components/ProgramCards/ProgramCards'
import Experimental from '@/authenticated/components/Experimetal'
import useAvailableUtilityPrograms from '@/app/hooks/useAvailableUtilityPrograms'
import { remoteConfig } from '@/firebase'
import UtilityProgramLearnMoreDashboardView from 'features/utility-programs/UtilityProgramLearnMoreDashboardView'
import useHideDashboardConfig from '@/authenticated/hooks/useHideDashboardConfig'
import { DashboardConfigType } from '@/types/dashboardConfig'

export function HomeDeviceSection(props: { homeId: ID }) {
  const profile = useProfile()
  const { data: home, isLoadingOrIdle: isLoadingHome } =
    homesCollection.useFetch()
  const { data: behavioralEnrollments, isLoadingOrIdle: isLoadingEnrollments } =
    behavioralEnrollmentCollection.useFetch({
      require: !!profile?.id,
    })

  // TODO AVild - isAva nonsense
  const avaId = remoteConfig?.getWhitelabelId('ava')
  const { firstAvailableProgram, isLoading: isLoadingFirstAvailable } =
    useAvailableUtilityPrograms()
  const isAva = firstAvailableProgram && firstAvailableProgram.id === avaId

  const isLoading =
    isLoadingHome || isLoadingEnrollments || isLoadingFirstAvailable

  const filteredEnrollment = behavioralEnrollments?.filter(
    (enrollment) => enrollment.profile_id === profile?.id,
  )?.[0]

  const [isEnrolledLocal, setIsEnrolledLocal] = useState(
    filteredEnrollment && filteredEnrollment.unenrolled_at === null,
  )

  const offerHidden = useHideDashboardConfig(DashboardConfigType.UtilityProgram)

  // If we're isAva and the user hasn't enrolled behaviorally
  if (isAva && !filteredEnrollment && !offerHidden) {
    return <UtilityProgramLearnMoreDashboardView />
  }

  const loadedContent = (
    <>
      <Experimental>
        <ProgramCards />
      </Experimental>
      <Card>
        {filteredEnrollment && (
          <UtilityProgramEnrollmentTogglePrompt
            isEnrolledValue={isEnrolledLocal}
            onEnrollToggleValueChange={(checked: boolean) =>
              setIsEnrolledLocal(checked)
            }
            deviceType="behavioral"
          />
        )}
        {!filteredEnrollment && (
          <div>
            <Text variant="h3" className="mb-4">
              Not Enrolled
            </Text>
            <Text variant="body4">
              There are not any home utility programs to offer you at this time.
            </Text>
          </div>
        )}
      </Card>
    </>
  )

  if (!profile) {
    return null
  }

  return (
    <>
      {isLoading && <LoadingAnimation type="falling" centered />}
      {!isLoading && loadedContent}
    </>
  )
}
