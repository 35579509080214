import { ConfigurableFunctionalityProvider } from '@/app/context/ConfigurableFunctionalityContext'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { useAppSelector, useUrlSearchParam } from '@/hooks'
import { Vehicle } from '@/types'
import theme from '@/v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import Tabs from '@/components/Tabs'
import ProgramTab from './ProgramTab'
import SettingsTab from './SettingsTab'
import { track } from '@/logging/mixpanel'
import { useHistory } from 'react-router-dom'

type Props = {
  vehicleId: Vehicle['id']
  hideChargeLimit?: boolean
}

type ViewProps = Omit<Props, 'vehicleId'> & {
  vehicle: Vehicle
}

function VehicleScheduleSectionView({
  vehicle,
  hideChargeLimit = false,
}: ViewProps) {
  const initialTab = useUrlSearchParam('tab')
  const tabs = ['Programs', 'Settings']
  const history = useHistory()

  const handleTabChange = (index: number) => {
    const tab = tabs[index]
    history.replace({ search: `?tab=${tab.toLowerCase()}` })
    track('Vehicle Schedule Tab Clicked', { tab_name: tab })
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-col items-center space-y-6">
        <Tabs.Tabs
          initialTabIndex={+(initialTab === 'settings')}
          tabKey="schedule-vehicle-tabs"
          labels={tabs}
          tabBarAttributes={{ className: 'max-w-none' }}
          onTabChange={handleTabChange}
        />
        <Tabs.Content tabKey="schedule-vehicle-tabs">
          <ProgramTab vehicle={vehicle} />
          <SettingsTab vehicle={vehicle} hideChargeLimit={hideChargeLimit} />
        </Tabs.Content>
      </div>
    </ThemeProvider>
  )
}

export default function VehicleScheduleSection(props: Props) {
  const user = useAppSelector((state) => state.user.user)
  const vehicle = useAppSelector((state) =>
    state.vehicles.vehicles?.find((vehicle) => vehicle.id === props.vehicleId),
  )
  const loading = useAppSelector(
    (state) => !state.vehicles.hasFetched || !user || !vehicle,
  )
  const noVehicleFound = useAppSelector(
    (state) => state.vehicles.hasFetched && !vehicle,
  )

  if (noVehicleFound) {
    return null
  }

  if (loading) {
    return (
      <div style={{ width: '100%', height: '400px' }}>
        <PageLoader />
      </div>
    )
  }

  return (
    <ConfigurableFunctionalityProvider scope="charging">
      <ConfigurableFunctionalityProvider
        pushScope="schedule"
        vehicleId={props.vehicleId}
      >
        <VehicleScheduleSectionView {...props} vehicle={vehicle!} />
      </ConfigurableFunctionalityProvider>
    </ConfigurableFunctionalityProvider>
  )
}
