import { Text } from '@/components'
import { Divider } from '@material-ui/core'
import InfoCard from '@/app/components/InfoCard/InfoCard'
import Card from '@/components/Card'
import Collapsible from '@/components/collapsibleList'
import { useAppSelector } from '@/hooks'
import { useParams } from 'react-router-dom'
import { useNavigation } from '@/app/hooks'
import Icon from '@/components/Icon/Icon'
import { vehicleConnectivityCollection } from '@/reducers/vehicleConnectivity'
import {
  ConnectivityConfigType,
  getConnectivityConfigStatus,
} from '@/app/pages/schedule/vehicle/connectivity/utils'
import { VehicleConnectivity } from '@/types/vehicle/vehicleConnectivity'
import { getCapabilityAccess, getCapabilitySubtitle } from './utils'

export default function DeviceSettingsCard() {
  const { deviceId } = useParams<{ deviceId: string }>()
  const navigate = useNavigation().push

  const selectedVehicle = useAppSelector((state) => {
    if (!deviceId) return null
    return state.vehicles.vehicles?.find(
      (vehicle) => vehicle.id === parseInt(deviceId),
    )
  })

  if (!selectedVehicle) return null

  const { data, status } = vehicleConnectivityCollection.useFetch(
    `vehicle/${selectedVehicle?.id}/connectivity`,
  )
  const isTesla = selectedVehicle.car.car_model?.make.toLowerCase() === 'tesla'
  const connectivity = data[0] as VehicleConnectivity | undefined
  const connectivityConfig = connectivity
    ? getConnectivityConfigStatus(connectivity)
    : null
  const connectivityIssues = connectivityConfig
    ? Object.values(connectivityConfig).filter((v) => v === 'failure').length
    : 0

  const {
    level: capabilityLevel,
    type: capabilityType,
    missingScopes,
  } = getCapabilityAccess(selectedVehicle)
  const fullPermissions = capabilityLevel === 'full'

  const capabilitySubtitle = getCapabilitySubtitle(selectedVehicle)

  const subscriptionStatus = connectivityConfig
    ? connectivityConfig[ConnectivityConfigType.Subscription]
    : null
  let subscriptionSubtitle = ''
  if (subscriptionStatus === 'failure') {
    subscriptionSubtitle = 'expired subscription'
  } else if (subscriptionStatus === 'success') {
    subscriptionSubtitle = `${isTesla ? 'free' : 'active'} subscription`
  } else if (subscriptionStatus === 'unknown') {
    subscriptionSubtitle = 'unknown subscription'
  }

  const connectivitySubtitle = `${
    connectivityIssues ? 'No' : 'Full'
  } connectivity, ${subscriptionSubtitle}`

  return (
    <Card>
      <Collapsible.List className="!py-0 !px-0 !border-0">
        <Collapsible.ListItem
          className="!p-0"
          isOpen={!!connectivityIssues || !fullPermissions}
          header={
            <div className="flex gap-2.5 py-4">
              <Icon name="Settings" className="text-themed-primary w-[24px]" />
              <Text variant="h3">Device Settings</Text>
            </div>
          }
        >
          <Divider className="my-4" />
          <InfoCard
            variant="large"
            image={
              <Icon
                name="Grid"
                className={`${
                  capabilityType === 'none'
                    ? 'text-themed-red-600'
                    : missingScopes.length > 0
                    ? 'text-themed-orange-500'
                    : 'text-themed-primary'
                } w-[20px] mr-2.5`}
              />
            }
            title="Capabilities"
            sheetColor={
              capabilityType === 'none'
                ? '#fb4242'
                : missingScopes.length
                ? '#fb8542'
                : undefined
            }
            sheetText={
              missingScopes.length
                ? `${missingScopes.length} missing`
                : undefined
            }
            subtitle={capabilitySubtitle}
            onClick={() => navigate(window.location.pathname + '/capability')}
          />
          <Divider className="my-4" />
          <InfoCard
            variant="large"
            image={
              <Icon
                name="Wifi"
                className={`${
                  connectivityIssues
                    ? 'text-themed-red-600'
                    : 'text-themed-primary'
                } w-[20px] mr-2.5`}
              />
            }
            sheetColor={connectivityIssues ? '#fb4242' : undefined}
            sheetText={
              connectivityIssues
                ? `${connectivityIssues} issue${
                    connectivityIssues === 1 ? '' : 's'
                  }`
                : undefined
            }
            title="Connectivity"
            subtitle={connectivitySubtitle}
            onClick={() => navigate(window.location.pathname + '/connectivity')}
          />
        </Collapsible.ListItem>
      </Collapsible.List>
    </Card>
  )
}
