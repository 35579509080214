import {
  getChargeSchedules,
  setChargeScheduleError,
  updateChargeSchedule,
} from '@/actions/schedule'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import {
  selectUserCanAndHasntIntegratedUtility,
  selectUserVehicleChargerIsProgramEligible,
} from '@/selectors'
import { VehicleCharger } from '@/types'
import {
  isVehicleChargerChargeSchedule,
  VehicleChargerChargeSchedule,
} from '@/types/vehicleCharger/chargeSchedule'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '@/v2/theme'
import { ConfigurableFunctionality } from '@/app/components'
import AllowChargeControlCard from '@/authenticated/components/ChargeSchedule/AllowChargeControlCard'
import { Collapse } from '@material-ui/core'
import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import PlanDetailsCard from '@/authenticated/components/ChargeSchedule/PlanDetailsCard'
import EnvironmentalChargingOptionsCard from '@/authenticated/components/ChargeSchedule/EnvironmentalChargingOptionsCard'
import { ButtonVariant } from '@/components'
import { ID } from '@/types/model'
import { PayloadAction } from '@reduxjs/toolkit'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { chargerEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'
import Experimental from '@/authenticated/components/Experimetal'
import { UtilityProgram } from '@/types/utilityProgram'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'
import UtilityProgramHelpers from '@/utils/utility-program-helpers/utilityProgramHelpers'
import ProgramCards from '@/app/components/ProgramCards/ProgramCards'
import {
  ChargingPreferencesHeader,
  ChargingPreferencesModal,
} from './CharingPreferences'
import { EVSEDeviceAlertMessage } from './EVSEDeviceAlertMessage'

type VehicleChargerScheduleSectionViewProps = {
  vehicleCharger: VehicleCharger
  buttonVariant?: ButtonVariant
}

function useVehicleChargerChargeScheduleFetcher(vehicleChargerId: ID) {
  const dispatch = useAppDispatch()
  const chargeScheduleExists = useAppSelector((state) => {
    const schedule = state.schedule.chargeSchedules?.find(
      (schedule) => schedule.charger_id === vehicleChargerId,
    )
    return Boolean(schedule)
  })
  const canFetchSchedules = useAppSelector((state) => {
    const { isLoading, errors } = state.schedule
    return !isLoading && !Object.keys(errors).length
  })

  useEffect(() => {
    if (chargeScheduleExists) {
      return
    }
    if (canFetchSchedules) {
      dispatch(getChargeSchedules()).then(
        (response: PayloadAction<VehicleChargerChargeSchedule[]>) => {
          const scheduleInPayload = response?.payload?.find(
            (schedule) => schedule.charger_id === vehicleChargerId,
          )
          if (!scheduleInPayload) {
            // As a fallback, we set an error if the charger isn't found
            dispatch(
              setChargeScheduleError(
                // @ts-expect-error createAction used in js file
                `Vehicle charger ${vehicleChargerId} not found`,
              ),
            )
          }
        },
      )
    }
  }, [chargeScheduleExists])
}

function VehicleChargerScheduleSectionView(
  props: VehicleChargerScheduleSectionViewProps,
) {
  const dispatch = useAppDispatch()
  const chargeSchedule = useAppSelector((state) => {
    const schedule = state.schedule.chargeSchedules?.find(
      (schedule) => schedule.charger_id === props.vehicleCharger.id,
    )
    if (schedule && isVehicleChargerChargeSchedule(schedule)) {
      return schedule as VehicleChargerChargeSchedule
    }
  })
  const [localChargeSchedule, setLocalChargeSchedule] = useState(chargeSchedule)
  const [chargingPrefsModalOpen, setChargingPrefsModalOpen] = useState(false)

  const { data: profileEnrollments } =
    utilityProgramEnrollmentCollection.useFetch()

  const userCanAndHasntConnectedUtility = useAppSelector(
    selectUserCanAndHasntIntegratedUtility,
  )
  const showProgramEnrollmentFlag = useFeatureFlag({
    flag: 'charger_enrollments',
  })
  const profileHasEnrolledInUtilityProgram = profileEnrollments.length > 0

  const vehicleChargerIsProgramEligible = useAppSelector((state) =>
    selectUserVehicleChargerIsProgramEligible(state, props.vehicleCharger.id),
  )
  const potentialProgram = useAppSelector((state) =>
    selectUtilityProgramForUserUtility(state),
  ) satisfies UtilityProgram | undefined

  // Logic to check to see if we have potential unenrolled programs.
  // This logic stinks but as we roll out multi-program functionality
  // it can be properly deprecated and banished to the pits of hell
  const userHasUnenrolledProgram =
    UtilityProgramHelpers.userHasUnenrolledBetterProgram(
      profileEnrollments,
      potentialProgram,
    )

  const shouldShowUtilityProgramEnrollment =
    profileHasEnrolledInUtilityProgram &&
    vehicleChargerIsProgramEligible &&
    showProgramEnrollmentFlag

  useVehicleChargerChargeScheduleFetcher(props.vehicleCharger.id)

  // Keep local schedule in sync with chargeSchedule
  useEffect(() => {
    if (localChargeSchedule?.id !== chargeSchedule?.id && chargeSchedule) {
      setLocalChargeSchedule(chargeSchedule)
    }
  }, [chargeSchedule, localChargeSchedule])

  const debouncedSave = useCallback(
    debounce((model) => {
      dispatch(updateChargeSchedule(model))
    }, 500),
    [],
  )

  const onChargeScheduleChange = (
    chargeSchedule: VehicleChargerChargeSchedule,
  ) => {
    setLocalChargeSchedule(chargeSchedule)
    debouncedSave(chargeSchedule)
  }

  return (
    <ThemeProvider theme={theme}>
      <EVSEDeviceAlertMessage />
      {/* <ControlledVehiclesCard vehicleChargerId={props.vehicleCharger.id} /> */}
      <ConfigurableFunctionality feature="chargingControl">
        <Experimental>
          <ProgramCards />
        </Experimental>
        <AllowChargeControlCard
          chargeSchedule={localChargeSchedule}
          onChange={onChargeScheduleChange}
          canCharge={true}
        />
        <Collapse in={localChargeSchedule?.allow_charging_control}>
          <ErrorBoundary
            location={LocationTag.PlanSelectCard}
            functionalityDescription="Charger Charge Planning Overview"
            fallbackOnCard
          >
            <PlanDetailsCard
              title={
                <ChargingPreferencesHeader
                  onInfoClick={() => setChargingPrefsModalOpen(true)}
                />
              }
            >
              <PlanDetailsCard.PlanSelectForm
                chargeSchedule={localChargeSchedule}
                onChange={onChargeScheduleChange}
              >
                <PlanDetailsCard.ComparePlansPromptAndDialog
                  includeScheduleDeparture={false}
                />
              </PlanDetailsCard.PlanSelectForm>
            </PlanDetailsCard>
          </ErrorBoundary>
        </Collapse>
      </ConfigurableFunctionality>
      <EnvironmentalChargingOptionsCard>
        {shouldShowUtilityProgramEnrollment ? (
          <EnvironmentalChargingOptionsCard.EnrollmentToggleHeader
            vehicleChargerId={props.vehicleCharger.id}
          />
        ) : (
          <EnvironmentalChargingOptionsCard.PlainHeader />
        )}
        <EnvironmentalChargingOptionsCard.AirQualityToggle
          chargeSchedule={localChargeSchedule}
          onChange={onChargeScheduleChange}
        />
        {userHasUnenrolledProgram ? (
          <EnvironmentalChargingOptionsCard.ProgramEnrollmentUpsell
            chargerId={props.vehicleCharger.id}
            buttonVariant={props.buttonVariant}
          />
        ) : shouldShowUtilityProgramEnrollment ? null : userCanAndHasntConnectedUtility ? (
          <EnvironmentalChargingOptionsCard.UtilityIntegrationUpsell
            chargeSchedule={localChargeSchedule}
            onChange={onChargeScheduleChange}
          />
        ) : (
          <EnvironmentalChargingOptionsCard.GridSuportToggle
            chargeSchedule={localChargeSchedule}
            onChange={onChargeScheduleChange}
          />
        )}
      </EnvironmentalChargingOptionsCard>
      <ChargingPreferencesModal
        open={chargingPrefsModalOpen}
        onClose={() => setChargingPrefsModalOpen(false)}
      />
    </ThemeProvider>
  )
}

type Props = { vehicleChargerId: VehicleCharger['id'] }

export default function VehicleChargerScheduleSection(props: Props) {
  const { status } = chargerEnrollmentCollection.useFetch()
  const charger = useAppSelector((state) =>
    vehicleChargersCollection.selectors.selectById(
      state,
      props.vehicleChargerId,
    ),
  )

  const loadingOrWaitingFetch = useAppSelector((state) => {
    const queryStates =
      vehicleChargersCollection.selectors.queryState.selectByResourceId(
        state,
        props.vehicleChargerId,
      )

    const unfetchedStateExists = queryStates.some((queryState) =>
      ['idle', 'loading'].includes(queryState.status),
    )
    return unfetchedStateExists
  })

  const noChargerFound = !loadingOrWaitingFetch && !charger

  if (noChargerFound) {
    return null
  }

  if (loadingOrWaitingFetch || status === 'loading') {
    return (
      <div style={{ width: '100%', height: '400px' }}>
        <PageLoader />
      </div>
    )
  }

  return charger ? (
    <VehicleChargerScheduleSectionView vehicleCharger={charger} />
  ) : null
}
