import { Vehicle } from '@/types'
import { TeslaFleetAuthenticationScope } from '@/types/teslaFleetAuthentication'
import { canCharge } from '@/utils/vehicles/permissions'

type CapabilityAccess = {
  level: 'full' | 'partial'
  type: 'data' | 'control' | 'none'
  missingScopes: (
    | TeslaFleetAuthenticationScope
    | 'public_key'
    | 'control'
    | 'data'
  )[]
}

const TeslaCapabilityRequirements = {
  data: ['public_key', 'vehicle_device_data'],
  control: [
    'vehicle_charging_cmds',
    'vehicle_location',
    'vehicle_device_data',
    'public_key',
  ],
}

function getMissingCapabilitiesTesla(
  vehicle: Vehicle,
): (TeslaFleetAuthenticationScope | 'public_key')[] {
  const missingScopes = vehicle.car.fleet_scopes?.missing ?? []
  const publicKeyNotAttached = vehicle.car.public_key_not_attached_at
  return [
    ...missingScopes,
    ...(publicKeyNotAttached ? ['public_key' as const] : []),
  ]
}

function getMissingCapabilitiesNonTesla(
  vehicle: Vehicle,
): ('control' | 'data')[] {
  const canControlCharge = canCharge(vehicle)
  return !canControlCharge ? ['control'] : []
}

export function getMissingCapabilities(vehicle: Vehicle): string[] {
  const isTesla = vehicle.car.car_model?.make.toLowerCase() === 'tesla'
  if (isTesla) {
    return getMissingCapabilitiesTesla(vehicle)
  } else {
    return getMissingCapabilitiesNonTesla(vehicle)
  }
}

function getCapabilityAccessTesla(vehicle: Vehicle): CapabilityAccess {
  const missingScopes = getMissingCapabilitiesTesla(vehicle)

  const hasReadCapability = !missingScopes.some((s) =>
    TeslaCapabilityRequirements.data.includes(s),
  )
  const hasWriteCapability = !missingScopes.some((s) =>
    TeslaCapabilityRequirements.control.includes(s),
  )

  const level = missingScopes.length > 0 ? 'partial' : 'full'
  const capabilityType = hasWriteCapability
    ? 'control'
    : hasReadCapability
    ? 'data'
    : 'none'

  return {
    level,
    type: capabilityType,
    missingScopes,
  }
}

function getCapabilityAccessNonTesla(vehicle: Vehicle): CapabilityAccess {
  const missingScopes = getMissingCapabilitiesNonTesla(vehicle)
  return {
    level: missingScopes.length > 0 ? 'partial' : 'full',
    type: missingScopes.includes('control') ? 'data' : 'control',
    missingScopes,
  }
}

export function getCapabilityAccess(vehicle: Vehicle): CapabilityAccess {
  const isTesla = vehicle.car.car_model?.make.toLowerCase() === 'tesla'
  if (isTesla) {
    return getCapabilityAccessTesla(vehicle)
  } else {
    return getCapabilityAccessNonTesla(vehicle)
  }
}

export function getCapabilitySubtitle(vehicle: Vehicle): string {
  const { level, type } = getCapabilityAccess(vehicle)
  let subtitle = ''

  const levelPrefix =
    level === 'full' ? 'Full permissions' : 'Partial permissions'

  if (type === 'none') {
    subtitle = 'Partial permissions, no capabilities'
  } else if (type === 'data') {
    subtitle = `${levelPrefix}, data only`
  } else if (type === 'control') {
    subtitle = `${levelPrefix}, control and data`
  }

  return subtitle
}
