import daysOfWeekToReadable from './daysOfWeekToReadable'
import toLongTimeString from './toLongTimeString'
import toShortTimeString from './toShortTimeString'

import { format, nextDay, set } from 'date-fns'
import { ChargeScheduleDaysOfWeek, DayOfWeek } from '@/types/chargeSchedule'

function convertTimeTo12HourFormat(time: string | null): string {
  if (!time) {
    return ''
  }

  const date = convertTimeStringToDate(time)
  if (!date) {
    return ''
  }

  return format(date, 'h:mm a')
}

function convertTimeStringToDate(time: string | null): Date | null {
  if (!time) {
    return null
  }

  const [hours, minutes, seconds] = time.split(':').map(Number)
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)

  return date
}

function isTodayOrTomorrow(day: Date): boolean {
  const today = new Date().getDay()
  const compDay = day.getDay()
  return (
    today === compDay || today === compDay - 1 || (today === 6 && compDay === 0)
  )
}

function getNextDepartureDate(charge: ChargeScheduleDaysOfWeek): Date | null {
  if (!charge.day_of_week || !charge.departure_time) {
    return null
  }
  return getNextDateForDayAndTime(charge.day_of_week, charge.departure_time)
}

function getNextChargeDate(charge: ChargeScheduleDaysOfWeek): Date | null {
  if (!charge.day_of_week || !charge.charging_start_time) {
    return null
  }

  return getNextDateForDayAndTime(
    charge.day_of_week,
    charge.charging_start_time,
  )
}

const dayMap: { [key in DayOfWeek]: Day } = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

function getNextDateForDayAndTime(dayOfWeek: DayOfWeek, time: string): Date {
  const now = new Date()
  const [hours, minutes, seconds] = time.split(':').map(Number)

  const targetDay = dayMap[dayOfWeek]
  const currentDay = now.getDay()

  // If the target day is today
  if (currentDay === targetDay) {
    const targetTime = set(now, { hours, minutes, seconds })

    // If the current time is before the target time, return today's date
    if (now < targetTime) {
      return targetTime
    }
  }

  // Otherwise, get the next occurrence of the specified day of the week
  const targetDate = nextDay(now, targetDay)

  // Set the time on the targetDate
  return set(targetDate, { hours, minutes, seconds })
}

export {
  toShortTimeString,
  toLongTimeString,
  daysOfWeekToReadable,
  isTodayOrTomorrow,
  convertTimeStringToDate,
  convertTimeTo12HourFormat,
  getNextDepartureDate,
  getNextChargeDate,
}
