import React from 'react'
import Card from '../Card'
import Text from '../Text'
import Sheet from '../Sheet'
import Icon from '../Icon'
import * as Icons from 'react-feather'
import Skeleton from '../Skeleton'
import { Identifiable } from '@/types'

type MenuCardItemProps = Identifiable<{
  label: string
  rightLabel: string
  sheetValue?: string
  onClick?: () => void
  loading?: boolean
}>

export function MenuCardItem({
  id,
  label,
  rightLabel,
  sheetValue,
  onClick,
  loading,
}: MenuCardItemProps) {
  return (
    <div
      id={id}
      key={id}
      onClick={onClick}
      className="flex items-center justify-between w-full py-1 mt-1 rounded cursor-pointer"
    >
      <div>
        {loading ? (
          <Skeleton
            variant="rect"
            className="rounded-[12px]"
            width={99}
            height={20}
          />
        ) : (
          <Text fontSize="16px">{label}</Text>
        )}
      </div>
      <div className="flex items-center">
        {loading ? (
          <Skeleton
            variant="rect"
            className="rounded-[12px]"
            width={99}
            height={20}
          />
        ) : (
          <>
            {sheetValue && (
              <Sheet color="#E5FBEC" className="mr-4">
                <Text className="text-themed-green-900">{sheetValue}</Text>
              </Sheet>
            )}
            <Text
              id={`${id}-right-label`}
              className="pr-2 font-bold text-themed-grey-500"
            >
              {rightLabel}
            </Text>
            <Icon
              color="#5d5d5d"
              name="ChevronRight"
              className="w-5 h-5 text-themed-grey-500"
            />
          </>
        )}
      </div>
    </div>
  )
}

type MenuCardProps = {
  title: string | React.ReactNode
  icon?: keyof typeof Icons
  iconColor?: string
  children?: React.ReactNode
  className?: string
}

export default function MenuCard({
  title,
  children,
  icon,
  iconColor,
  className,
}: MenuCardProps) {
  return (
    <Card className={className}>
      <span className="flex items-center mb-1">
        {icon && <Icon name={icon} color={iconColor ?? ''} className="mr-2" />}
        <Text variant="h3">{title}</Text>
      </span>
      <div>{children}</div>
    </Card>
  )
}
