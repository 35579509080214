import { Vehicle } from '@/types'
import useViewModel from './useViewModel'
import ChargeSettingsCard from '@/app/pages/devices/vehicle/SettingsTab/ChargeSettingsCard/ChargeSettingsCard'
import DeviceSettingsCard from './DeviceSettingsCard/DeviceSettingsCard'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'

type Props = {
  vehicle: Vehicle
  hideChargeLimit: boolean
}

const SettingsTab = (props: Props) => {
  const viewModel = useViewModel(props.vehicle)
  const deviceSettings = useFeatureFlag({
    flag: 'device_settings',
    experimentalValue: true,
  })

  return (
    <div id="controls" className="w-full flex flex-col gap-6">
      {deviceSettings && <DeviceSettingsCard />}
      <ChargeSettingsCard />
    </div>
  )
}

export default SettingsTab
