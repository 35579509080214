import { Vehicle } from '@/types'
import VehicleProgramCard from './VehicleProgramCard'
import Experimental from '@/authenticated/components/Experimetal'
import ProgramCards from '@/app/components/ProgramCards/ProgramCards'

type Props = {
  vehicle: Vehicle
}

const ProgramTab = (props: Props) => {
  return (
    <section className={'flex flex-col gap-4'}>
      <Experimental>
        <ProgramCards />
      </Experimental>
      <div id="schedule-vehicle-programs-tab" className="flex flex-col w-full">
        <VehicleProgramCard vehicle={props.vehicle} />
      </div>
    </section>
  )
}

export default ProgramTab
