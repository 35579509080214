import { ConfigurableText } from '@/app/components'
import { Button, Modal, Icon } from '@/components'
import useRemoteConfig from '@/hooks/useRemoteConfig'

export function ChargingPreferencesHeader(props: { onInfoClick: () => void }) {
  return (
    <div className="flex flex-row mb-8 items-center">
      <ConfigurableText variant="h3">Charging Preferences</ConfigurableText>
      <Button
        id="charging-prefs-info-button"
        variant="icon"
        onClick={props.onInfoClick}
      >
        <Icon
          name="HelpCircle"
          size={18}
          color="base-blue-500"
          className="align-middle"
        />
      </Button>
    </div>
  )
}

export function ChargingPreferencesModal(props: {
  open: boolean
  onClose: () => void
}) {
  const { appRemoteConfig } = useRemoteConfig()
  const targetKwh = appRemoteConfig?.getNumber('evse_target_kwh') ?? 48

  return (
    <Modal
      id="charging-prefs-info-modal"
      open={props.open}
      title="Charging Preferences"
      onClose={props.onClose}
    >
      <ConfigurableText variant="body2" className="mb-5">
        Since you haven't connected your EV yet, we don't know how much charge
        your car needs or by when. We'll assume you need add {targetKwh} kWh by
        8:30 AM and will keep charging to your vehicle's limit if {targetKwh}{' '}
        kWh isn't enough. We'll also include your Save the Planet and Save Money
        preferences to decide when to start charging.
      </ConfigurableText>
      <Button
        id="charging-prefs-info-modal-ack-button"
        variant="primary"
        onClick={props.onClose}
      >
        Understood
      </Button>
    </Modal>
  )
}
