import { AnimatePresence, motion } from 'framer-motion'
import useHasRendered from '@/hooks/useHasRendered'
import { AnimationProps } from '@/components/Animations/AnimationProps'

export default function DropdownAnimation(props: AnimationProps) {
  const hasRenderedOnce = useHasRendered()

  return (
    <AnimatePresence>
      {props.show && (
        <motion.div
          initial={hasRenderedOnce ? { height: 0, opacity: 0 } : false}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{
            height: { duration: 0.2, ease: 'easeInOut' },
            opacity: { duration: 0.12 },
          }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
