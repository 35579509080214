import { FunctionalityConfig } from '@/app/context/ConfigurableFunctionalityContext'
import { Model, ID } from '../model'
import { DocumentRendererProps } from '@keystone-6/document-renderer'
import { UtilitiesQuery } from '@/generated/graphql'

type GraphQLObject<T> = T & {
  __typename?: string
}

type RichTextDocument = DocumentRendererProps['document']

type HTML = string

export type ViewConfigText =
  | string
  | GraphQLObject<{
      text?: string
      color?: string
    }>

export type ViewConfigButton = GraphQLObject<{
  backgroundColor?: string
  color?: string
  text?: string
}>

export type ViewConfigModal = GraphQLObject<{
  title?: string
  description?: HTML | string
  primaryButton?: ViewConfigButton
}>

export type EnrollConfirmationModal = ViewConfigModal & {
  tosDocument?: RichTextDocument
}

export type OnboardingViewConfig = UtilityProgramViewConfig['onboarding']

export type SchedulerViewConfig = UtilityProgramViewConfig['scheduler']

export type LearnMoreViewConfig = UtilityProgramViewConfig['learnMore']

export type AppFunctionalityConfig = {
  charging?: {
    schedule?: { [key: string]: FunctionalityConfig }
    calendar?: { [key: string]: FunctionalityConfig }
  }
  onboarding?: { [key: string]: FunctionalityConfig }
}

export type ReferralConfig = UtilityProgramViewConfig['referral']

export type FaqConfig = UtilityProgramViewConfig['faq']

export type ChargeForecastConfig = UtilityProgramViewConfig['chargeForecast']

export type UtilityProgramConnectViewConfig =
  UtilityProgramViewConfig['utilityProgramConnect']

export type EligibleDeviceEnrollmentConfig =
  UtilityProgramViewConfig['eligibleDeviceEnrollment']

export type UtilityProgramViewConfig = Exclude<
  UtilitiesQuery['resolvedGroupViewConfig'],
  null | undefined
>

export type EnrollmentVerificationStatus =
  | 'PENDING'
  | 'VERIFIED'
  | 'MORE_INFO'
  | 'NEW_INFO'
  | 'INELIGIBLE'
  | 'AUTO_PENDING'
  | 'ABANDONED'

export enum EnrollmentVerificationStatusColor {
  PENDING = 'text-base-orange-500',
  VERIFIED = 'text-base-green-900',
  MORE_INFO = 'text-base-red-500',
  NEW_INFO = 'text-base-purple-500',
  INELIGIBLE = 'text-base-red-500',
  AUTO_PENDING = 'text-base-orange-500',
  ABANDONED = 'text-base-gray-500',
}

export type UtilityEnrollmentWithProgram = {
  id: number
  unenrolled_at: string
  utility_program: UtilityProgram
  vehicle_enrollments: Array<{
    id: number
    vehicle_id: number
    unenrolled_at: string | null
    ineligible: boolean
    utility_program_enrollment_id: number
    group: string
    utility_program_id: string
    unenrolled_reasons: string
    unenrolled_source: string
  }>
  thermostat_enrollments: Array<{
    id: number
    thermostat_id: number
    unenrolled_at: string
    ineligible: boolean
    utility_program_enrollment_id: number
    group: string
    utility_program_id: string
    unenrolled_reasons: string
    unenrolled_source: string
  }>
  charger_enrollments: Array<{
    id: number
    charger_id: number
    unenrolled_at: string
    ineligible: boolean
    utility_program_enrollment_id: number
    group: string
    utility_program_id: string
    unenrolled_reasons: string
    unenrolled_source: string
  }>
  behavioral_enrollments: Array<{
    id: number
    profile_id: number
    unenrolled_at: string
    ineligible: boolean
    utility_program_enrollment_id: number
    group: string
    utility_program_id: string
    unenrolled_reasons: string
    unenrolled_source: string
  }>
  external_redirect_url: string
  verification_status: EnrollmentVerificationStatus
  verified_at: string
}

export type UtilityProgram = Model & {
  id: ID
  name: string
  total_incentives_usd?: number
  logo_url: string | null
  tos_url: string | null
  faq_url: string
  referral_points?: number
  unenrolled_survey_url: string
  utility_ids: ID[]
  external_id?: string
  preferred_display_timezone?: string
  view_config_json: UtilityProgramViewConfig
  verification_status?: EnrollmentVerificationStatus
  device_eligibility?: {
    eligible_vehicle_ids: ID[]
    ineligible_vehicle_ids: ID[]
    eligible_thermostat_ids: ID[]
    ineligible_thermostat_ids: ID[]
    eligible_charger_ids: ID[]
    ineligible_charger_ids: ID[]
  }
}
