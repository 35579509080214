import { AlertMessage, AlertMessageLink } from '@/app/components'
import { Text } from '@/components'
import { useAppSelector } from '@/hooks'

function OtherVehicleChargingAlertMessage() {
  return (
    <AlertMessage variant="info">
      Other Vehicles
      <Text variant="body4" className="mt-2 text-inherit">
        Settings for this charger will apply to vehicles that use this charger,
        but are not connected to Optiwatt.
      </Text>
    </AlertMessage>
  )
}

function ConnectEVSECTAAlertMessage() {
  return (
    <AlertMessageLink
      variant="info"
      linkTo="/connect-device/vehicle"
      linkText="Connect your EV now"
    >
      We see a charger but no car. Optiwatt works better when you connect your
      EV so that we know how much it needs to charge and by when.
    </AlertMessageLink>
  )
}

export function EVSEDeviceAlertMessage() {
  const userHasVehicles = useAppSelector((state) =>
    Boolean(state.vehicles.vehicles?.length),
  )

  if (userHasVehicles) {
    return <OtherVehicleChargingAlertMessage />
  } else {
    return <ConnectEVSECTAAlertMessage />
  }
}
