import {
  DeviceSelection,
  DeviceType,
} from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import { DashboardLayout } from '@/app/layouts'

import { useAppDispatch, useAppSelector, useMockableViewModel } from '@/hooks'
import { useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import VehicleScheduleSection from './vehicle'
import { selectDevicesHaveLoaded } from '@/selectors'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import ThermoStatScheduleSection from './thermostat'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import { Thermostat } from '@/types'
import VehicleChargerScheduleSection from './vehicle-charger'
import { camelToKebabCase, kebabToCamelCase } from '@/utils/stringUtilities'
import Tabs from '@/components/Tabs'
import Experimental from '@/authenticated/components/Experimetal'
import { enrolledUtilityProgramsCollection } from '@/reducers/enrolledUtilityPrograms'
import BrandedHeader from '@/app/components/NavigationPage/WhitelabeledNavHeader/BrandedHeader'
import { HomeDeviceSection } from '@/app/pages/devices/home/HomeDeviceSection'
import { useProgramDisabledDevices } from '@/hooks/useProgramDisabledDevices'
import { AlertMessage } from 'app-components'
import { LoadingAnimation } from '@/components'
import ProgramCards from '@/app/components/ProgramCards/ProgramCards'
import VehiclePausedDataAlert from '../app/dashboard/sections/VehicleSection/VehicleSectionHeader'

type PathParams = {
  deviceType: 'vehicle' | 'thermostat' | 'vehicle-charger' | undefined
  deviceId: string | undefined
}

function useLiveViewModel() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { path } = useRouteMatch()
  const { deviceType: kebabDeviceType, deviceId } = useParams<PathParams>()
  const deviceType = kebabDeviceType
    ? (kebabToCamelCase(kebabDeviceType) as DeviceType)
    : undefined

  const selectedDevice: DeviceSelection | null =
    deviceType && deviceId
      ? {
          id: parseInt(deviceId),
          type: deviceType,
        }
      : null

  const setSelectedDevice = (device: DeviceSelection) => {
    const kebabDeviceType = camelToKebabCase(device.type)
    const basePath = path.includes('schedule') ? '/schedule' : '/devices'
    history.push({
      pathname: `${basePath}/${kebabDeviceType}/${device.id}`,
      search: history.location.search,
    })
  }

  const devicesLoaded = useAppSelector(selectDevicesHaveLoaded)

  const showThermostats = useFeatureFlag({
    flag: 'thermostat_schedule_settings',
    experimentalValue: true,
  })
  const showNestThermostats = useFeatureFlag({
    flag: 'nest_schedule_settings',
    // don't event show for experimental users, its just broken right now
    experimentalValue: true,
  })

  const excludedDevices = {
    home: () => false,
    thermostat: (tstat: Thermostat) =>
      !showThermostats || (tstat.portal === 'Nest' && !showNestThermostats),
    vehicle: () => false,
    vehicleCharger: () => false,
  }

  useEffect(() => {
    dispatch(enrolledUtilityProgramsCollection.actions.invalidate())
  }, [])

  return {
    path,
    selectedDevice,
    setSelectedDevice,
    excludedDevices,
    // if the devices haven't loaded, we dont want to prematurely render the page
    // and show the device selector.
    // TODO: create a loading state for the device selector instead
    loading: !devicesLoaded,
  }
}

const useMockViewModel = () => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceSelection | null>(
    null,
  )
  const excludedDevices = {
    home: () => false,
    thermostat: () => false,
    vehicle: () => false,
    vehicleCharger: () => false,
  }
  return {
    selectedDevice,
    setSelectedDevice,
    path: '',
    loading: false,
    excludedDevices,
  }
}

const useViewModel = useMockableViewModel({
  useViewModel: useLiveViewModel,
  useMockViewModel,
})

function DeviceSection({ device }: { device: DeviceSelection | null }) {
  if (!device) {
    return null
  }

  const {
    deviceIsDisabled: deviceDisabledByProgram,
    disabledMessage,
    isLoading,
  } = useProgramDisabledDevices()

  if (isLoading) {
    return <LoadingAnimation type="falling" centered />
  }

  if (deviceDisabledByProgram(device.type)) {
    return <AlertMessage variant="info">{disabledMessage}</AlertMessage>
  }

  return {
    vehicle: <VehicleScheduleSection vehicleId={device.id} />,
    thermostat: <ThermoStatScheduleSection thermostatId={device.id} />,
    home: <HomeDeviceSection homeId={device.id} />,
    vehicleCharger: (
      <VehicleChargerScheduleSection vehicleChargerId={device.id} />
    ),
  }[device.type]
}

function VehiclePausedDataAlertWrapper(props: {
  selectedDevice: DeviceSelection | null
}) {
  if (props.selectedDevice?.type !== 'vehicle' || !props.selectedDevice?.id) {
    return null
  }

  return <VehiclePausedDataAlert vehicleId={props.selectedDevice.id} />
}

function SchedulePageView(props: ReturnType<typeof useViewModel>) {
  if (props.loading) {
    return <PageLoader />
  }

  return (
    <>
      <BrandedHeader />
      <DashboardLayout
        selectedDevice={props.selectedDevice}
        setSelectedDevice={props.setSelectedDevice}
        title={'Devices'}
        excludeDevices={props.excludedDevices}
      >
        <VehiclePausedDataAlertWrapper selectedDevice={props.selectedDevice} />
        <DeviceSection device={props.selectedDevice} />
        <Experimental>
          <>{!props.selectedDevice && <NoDeviceProgramsView />}</>
        </Experimental>
      </DashboardLayout>
    </>
  )
}

export default function SchedulePage() {
  const viewModel = useViewModel()

  return <SchedulePageView {...viewModel} />
}

function NoDeviceProgramsView() {
  const tabs = ['Programs', 'Settings']
  return (
    <div className="flex flex-col items-center space-y-6">
      <Tabs.Tabs
        initialTabIndex={0}
        tabKey="no-device-programs-tabs"
        labels={tabs}
        tabBarAttributes={{ className: 'max-w-none' }}
        disabledTabIndexes={[1]}
      />
      <Tabs.Content tabKey="no-device-programs-tabs">
        <div className={'flex justify-start w-full'}>
          <ProgramCards />
        </div>
        <div></div>
      </Tabs.Content>
    </div>
  )
}
